.navbar-default{
	
}
.navbar-default .navbar-nav > li > a{
	
}
.navbar-default .navbar-nav > .dropdown > a .caret{
	
}
.navbar-default .navbar-brand{
	
}
.menu-large {
	position: static !important;
}
.megamenu{
	padding: @font-size-base 0px;
	width:100%;
	background-color: #dcdcdc;
}
.megamenu-inverse {
	background-color: @navbar-inverse-bg;
}
.megamenu> li > ul {
	padding: 0;
	margin: 0;
}
.megamenu> li > ul > li {
	list-style: none;
}
.megamenu> li > ul > li > * {
	display: block;
	padding: floor(((@font-size-base / 3))) floor(((@font-size-base * 1)));
}
.megamenu> li > ul > li > a {
	clear: both;
	font-weight: normal;
	white-space: normal;
	font-size: @font-size-small;
}
.megamenu> li ul > li > a:hover,
.megamenu> li ul > li > a:focus {
	text-decoration: none;
	background-color: @link-color;
	color: #fff;
}
.megamenu.disabled > a,
.megamenu.disabled > a:hover,
.megamenu.disabled > a:focus {
	
}
.megamenu.disabled > a:hover,
.megamenu.disabled > a:focus {
	text-decoration: none;
	background-color: transparent;
	background-image: none;
	filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
	cursor: not-allowed;
}
.megamenu .dropdown-header {
	font-weight: bold;
	text-align: left;
	color: @gray;
}
.megamenu .dropdown-header a {
	font-weight: bold;
	color: #595b5f;
}
.megamenu .divider {
	margin: 0;
	background-color: @gray-light;
}
@media (max-width: 768px) {
	.megamenu{
		margin-left: 0 ;
		margin-right: 0 ;
	}
	.megamenu> li {
		margin-bottom: 30px;
	}
	.megamenu> li:last-child {
		margin-bottom: 0;
	}
	.megamenu.dropdown-header {
		padding: 3px 15px !important;

	}
	.navbar-nav .open .dropdown-menu .dropdown-header{
		
	}
}