//
// Utility classes
// --------------------------------------------------


// Floats
// -------------------------

.clearfix {
  .clearfix();
}
.center-block {
  .center-block();
}
.pull-right {
  float: right !important;
}
.pull-left {
  float: left !important;
}


// Toggling content
// -------------------------

// Note: Deprecated .hide in favor of .hidden or .sr-only (as appropriate) in v3.0.1
.hide {
  display: none !important;
}
.show {
  display: block !important;
}
.invisible {
  visibility: hidden;
}
.text-hide {
  .text-hide();
}


// Hide from screenreaders and browsers
//
// Credit: HTML5 Boilerplate

.hidden {
  display: none !important;
}


// For Affix plugin
// -------------------------

.affix {
  position: fixed;
}


// No-wrap
// -------------------------

.nowrap {
  white-space: nowrap;
}


// No-padding
// -------------------------

.zero-padding {
  padding: 0;
}

.zero-padding-h {
  padding-left: 0;
  padding-right: 0;
}

.zero-padding-v {
  padding-top: 0;
  padding-bottom: 0;
}


// Type
// -------------------------

.uppercase {
  text-transform: uppercase;
}

.capitalize {
  text-transform: capitalize;
}

.lowercase {
  text-transform: lowercase;
}

.serif {
  font-family: @font-family-serif;
}

.sans {
  font-family: @font-family-sans-serif;
}
