//
//
// Pop-up Basket (Modal version)
// =============================

#popupBasketModal .modal-header,
#popupBasketModal .modal-footer {
	background-color: @gray-lighter;
}

#popupBasketModal .modal-header {
	border-bottom: 1px solid @brand-primary;
}

#popupBasketModal .modal-footer {
	border-top: 1px solid @brand-primary;
}

#popupBasketModal .modal-title,
#popupBasketModalBodyWrapper .pubsk-prod-title a:link,
#popupBasketModalBodyWrapper .pubsk-prod-title a:visited,
#popupBasketModal .pubsk-total-header, {
	.text-muted;
}

#popupBasketModalBodyWrapper .pubsk-prod-title a:hover {
	color: @link-color;
}

#popupBasketModal .modal-title,
#popupBasketModal .pubsk-header {
	font-size: @font-size-h4;
}

#popupBasketModalBodyWrapper .pubsk-header {
	.page-header;
	margin-top: 0;
}

#popupBasketModalBodyWrapper .row-pubsk-header {
	padding: 0 15px 5px 15px;
}

#popupBasketModalBodyWrapper .pubsk-header-desc {
	font-size: 0.7em;
}

#popupBasketModalBodyWrapper {
	padding: 0;
	max-height: 337px;
	overflow-x: hidden;
	overflow-y: scroll;
	border-radius: 0;
	padding: @modal-inner-padding;
}

#popupBasketModalBodyWrapper .pubsk-prod-title {
	font-size: ceil((@font-size-base * 0.92));
	font-weight: bold;
}

#popupBasketModalBodyWrapper .pubsk-prod-action {
	.small;
}

#popupBasketModalBodyWrapper .pubsk-input-qty {
	text-align: center;
	font-weight: bold;
}

#popupBasketModalBodyWrapper .sell-price {
	font-size: ceil((@font-size-base * 0.95));
}

#popupBasketModal .pubsk-total-header {
	padding: 0 15px 3px 15px;
	text-align: right;
}

#pubskFooterOrderProductsTotal,
#pubskFooterSavings {
	.hidden-xs;
}





//
//
// Sunrise Popup Basket (fixed-top version)
// ========================================

#popupBasketContent {
	position: relative;
}

#popupBasketTemplateWrapper {
	position: absolute;
	top: 0;
	right: 0;
	// width xs screen minus gutter margin
	width: (320 - floor(@grid-gutter-width / 2));
	z-index: 1000;
}

#popupBasketTemplateWrapper .pubsk-body {
	max-height: 200px;
	overflow-y: scroll;
	overflow-x: hidden;
}
