//
// SUNRISE SPECIFIC STYLES
// --------------------------------------------------

.sunrise-nav-bottom {
	margin-top: @grid-gutter-width;
	margin-bottom: @grid-gutter-width;
}

// Product Catalog (results listing)

.sunrise-product-catalog {

}

.sunrise-product-catalog-image {

}

.sunrise-product-catalog-content {

}

.catalogue-item {
	margin-top: (@line-height-computed * 2);
}

.fadeout {
	position: absolute;
	bottom: 0em;
	width:100%;
	height: @font-size-base * 4;
	// Dont' forget to convert the .sunrise-product-catalog-item background-color to RGB for this gradient:
	#gradient > .vertical(@start-color: rgba(237,237,237,.0001); @end-color: rgba(237,237,237,1));
}

.sunrise-product-catalog-content .content-title {
	font-size: @font-size-h4;
}

.sunrise-product-catalog-content .content-desc {
	font-size: @font-size-small;
}

.product-description {
	margin-top: ((@line-height-computed / 2) - 1);
}

.sunrise-product-catalog-sell {
	padding-top: @font-size-base;
}



//
//
// Product Detail

.sunrise-product-detail {

}

.sunrise-product-detail-image {
	padding-top: floor((@grid-gutter-width / 2)); // Same as .col-xx-Y padding (mixins.less)
	padding-bottom: floor((@grid-gutter-width / 2)); // Same as .col-xx-Y padding (mixins.less)
}

.sunrise-product-detail-content {
	padding-top: @grid-gutter-width;
}

.sunrise-product-detail-content h3 {
	color: @gray-dark;
}

.sunrise-product-detail-content .content-title {

}

.sunrise-product-detail-content .content-desc {

}

.sunrise-product-detail-footer {
	margin-top: @grid-gutter-width;
}

.sunrise-product-detail-table {

}


// Catalogue -narrow (product thumbnails)

.catalogue-row h1.prod-title,
.catalogue-row h2.prod-title,
.catalogue-row h3.prod-title,
.catalogue-row h1.prod-brand,
.catalogue-row h2.prod-brand,
.catalogue-row h3.prod-brand {
	margin: 0;
	font: @font-size-base/@line-height-computed @font-family-serif;
}

.prod-promos,
.prod-price,
.prod-buttons {
	margin-top: 5px;
	padding: 5px 0;
	min-height: (@line-height-computed + 10px);
	.clearfix();
}

.prod-promos .label-promo {
	font: bold floor((@font-size-base * 0.9))/@line-height-computed @font-family-sans-serif;
}



// Show available Stock

.stock-status {
	display: inline-block;
	margin: 0;
	padding: floor((@font-size-small / 3)) @font-size-small;
	.border-top-radius(@border-radius-small);
	.border-bottom-radius(@border-radius-small);
	color: #fff;
	border-width: 0;
	border-style: solid;
	border-color: transparent;
	border-bottom: none;
}
.stock-status.stockLow {
	background-color: @brand-danger;
	border-width: 0;
}
.stock-status.stockNormal {
	background-color: lighten(@brand-success, 15%);
	border-color: lighten(@brand-success, 15%);
	border-width: 2px;
}
.stock-status.stockHigh {
	background-color: @brand-success;
	border-color: @brand-success;
	border-width: 5px;
}
.stock-status .sr-only.stock-label {
	// position: relative; // Uncomment this line to show stock labels
}



// Shopping Basket

#divLoadingBasket,
#divLoadingModalBasket {
	background: transparent url(//cdn.hetwijnhuis.be/images/interface/smk-theme/loading.gif) no-repeat top left;
	min-height: 26px;
	padding-left: 36px;
	font-size: @font-size-h3;
	font-weight: bold;
}

.sunrise-basket {

}

.basket-prod-title {
	font-size: @font-size-base;
}

.sunrise-basket .panel-group {
	margin-bottom: 0;
}

.sunrise-basket .panel,
.sunrise-basket .panel .panel-heading,
.sunrise-basket .panel .panel-body {
	padding: floor((@font-size-base / 2));
	border: none !important;
	background-color: transparent;
	.box-shadow(none);
}

.sunrise-basket .panel {
	padding: 0;
}

.sunrise-basket .qtyInput {
	width: 40px;
	margin-top: 0px;
	float: left;
	height: 34px;
	text-align: center;
}

.sunrise-basket .item-service-links {

}

.sunrise-basket .text-success {
	color: @brand-promo;
	font-weight: bold;
}

.sunrise-basket-totals {
	background-color: @gray-lighter;
}

.sunrise-basket-nav {
	margin-top: @line-height-computed;
	margin-bottom: @line-height-computed;
}

.sunrise-basket td.valign-middle {
	vertical-align: middle;
}

.sunrise-basket .sunrise-basket-header-desc {
	font-size: 0.7em;
	padding-bottom: 0.35em;
	white-space: nowrap;
	color: @text-muted;
}

.sunrise-basket-footer {
	margin-top: (@line-height-computed * 3);
	padding: 0 15px;
	border: 1px solid @gray-light;
	background-color: @gray-lighter;
	.border-top-radius(@border-radius-base);
	.border-right-radius(@border-radius-base);
	.border-bottom-radius(@border-radius-base);
	.border-left-radius(@border-radius-base);
}

.sunrise-basket-footer .basket-subtotals,
.sunrise-basket-footer .basket-shipping,
.sunrise-basket-footer .basket-totals {
	padding: 15px 0;
}

.sunrise-basket-footer .basket-shipping,
.sunrise-basket-footer .basket-totals {
	border-top: 1px solid @gray-light;
}



// Sunrise Search Engine

.jumbotron.sunrise-search-engine {
	padding: floor((@jumbotron-padding / 3));
	margin-bottom: 0;
}

.sunrise-search-engine .search-keyword,
.sunrise-keywords-form .input-group-addon,
.sunrise-keywords-form .input-group-addon .btn {
	padding-top: 0;
	padding-bottom: 0;
}
.sunrise-keywords-form .btn-link {
	padding: 0;
}
.sunrise-keywords-form .input-group-addon {
	border: none;
}
.sunrise-keywords-form .sunrise-keywords-input-bar {
	border: none;
}

.sunrise-keywords-form > .form-group {
	margin-bottom: 0;
}


// Brands sections to display one brand per line

.brand-section {
	margin-top: (@line-height-computed * 2);
}

.brand-container {

}

.brand-row {

}


// Brands page sidebar navigation
// (works together with Affix Plugin)
// Ref: http://getbootstrap.com/javascript/#affix
// E.G.:
// <ul id="sidebar" class="nav nav-stacked" data-spy="affix" data-offset-top="400" data-offset-bottom="80">

#sidebar {
	font-size: @font-size-small;
}

#sidebar > li > strong {
	display: block;
	padding-top: 0.75em;
}

#sidebar > li > a {
	padding: 0.2em 0.75em;
}

.affix {
	top: 0;
}

.affix-top {

}

.affix-bottom {
  position: absolute;
}



//Brandgroup Menu (@SUNRISE_BRANDGROUPMENU)
#brandgroupmenu {}
#brandgroupmenu .brandgroup-item {}
#brandgroupmenu .brandgroup-name {}

// Brandgroups Icons Menu (@SUNRISE_BRANDGROUPICONMENU)

#brandgroupiconmenu {
	padding: @padding-base-vertical 0;
	text-align: center;
}

#brandgroupiconmenu .brandgroup-item {
	vertical-align: top;
	width: floor((@container-large-desktop / 12 - (@padding-base-horizontal * 2)));
}

#brandgroupiconmenu .brandgroup-image {}

#brandgroupiconmenu .brandgroup-name {
	font-size: @font-size-small;
	font-weight: bold;
	display: block;
	padding: @padding-small-horizontal 0;
}


// Promo ribbon

.ribbon-wrapper {
	width: 85px;
	height: 88px;
	overflow: hidden;
	position: absolute;
	top: 0;
	right: 15px;
}

.ribbon {
	font: bold 12px sans-serif;
	text-align: center;
	-webkit-transform: rotate(45deg);
	-moz-transform:    rotate(45deg);
	-ms-transform:     rotate(45deg);
	-o-transform:      rotate(45deg);
	position: relative;
	padding: 5px 0;
	left: 0;
	top: 15px;
	width: 120px;
}

.ribbon.promo {
	color: #fff;
	background-color: @brand-promo;
}


// Favourite wines

.catalogue-item .favproduct,
.sunrise-product-detail .favproduct {
	line-height: floor((@line-height-computed / 2));
}

.favlink.favlink-add {
	color: @text-muted;
}

.favlink.favlink-add:hover {
	color: @link-color;
}



// Hide order button from ordered wines history

#CustomerCatalogue .sell-add {
	display: none;
}


// Hide border and box shadow when needed
.noborder {
	border: none !important;
	-webkit-box-shadow: none !important;
	box-shadow: none !important;
}


// Sunrise and DOTNET fixes

// #form1, .aspNetHidden, .emptyContentHeader, .emptyContentContainer {
//   .hidden;
// }


//
// USER COMMON Sidebar navigation
@media screen and (max-width: 768px) {
  .row-offcanvas {
    position: relative;
    -webkit-transition: all 0.25s ease-out;
    -moz-transition: all 0.25s ease-out;
    transition: all 0.25s ease-out;
  }

  .row-offcanvas-left .sidebar-offcanvas {
    left: -40%;
  }

  .row-offcanvas-left.active {
    left: 40%;
  }

  .sidebar-offcanvas {
    position: absolute;
    top: 0;
    width: 40%;
    margin-right: @padding-base-horizontal;
  }

  .nav-sidebar > li,
  .sidebar-header {
    padding-left: floor((@padding-base-horizontal / 2));
  }

  .nav-sidebar li a.sidebar-link {
    padding-left: floor((@padding-base-horizontal * 1.5));
  }
}

.nav-sidebar {
  background-color: @gray-light;
  margin-right: -@padding-base-horizontal;
  margin-bottom: @padding-base-vertical;
  margin-left: -@padding-base-horizontal;
  border-radius: @border-radius-base;
}

.sidebar-header {
  font-family: @headings-font-family;
  font-weight: @headings-font-weight;
  font-size: @font-size-base;
  line-height: @headings-line-height;
  color: @headings-color;
  margin-top: @line-height-computed;
  margin-bottom: floor((@line-height-computed / 2));
}

.nav-sidebar > li {
  display: block;
}

.nav-sidebar > li > a {
  padding-right: @padding-base-horizontal;
  padding-left: @padding-base-horizontal;
}

.nav-sidebar > .active > a {
  font-weight: @headings-font-weight;
  color: @dropdown-link-active-color;
  background-color: @dropdown-link-active-bg;
}



// Checkout with steps progress bar

.checkout-header-top,
.checkout-header-bottom,
.checkout-content {
  margin-top: @line-height-computed;
}

.checkout-steps-sidebar {
  padding: 0.8em 1em;
  text-align: center;
}

.checkout-steps-bar {
  text-align: center;
  vertical-align: middle;
}

.checkout-steps-bar .checkout-step-wrapper {
  padding: 0 @padding-base-horizontal 0 0;
}

.checkout-step {
  .box-shadow(none) !important;
  cursor: not-allowed;
}

.checkout-step.first-step {}

.checkout-step.last-step {}

.checkout-step.active {
  cursor: default;
}

.checkout-step.completed {
  cursor: pointer;
}

.checkout-step-title {
  font-weight: bold;
  font-size: @font-size-h3;
}

.checkout-step-description {
  font-size: @font-size-small;
}

.checkout-sidebar-title {
  font-size: @font-size-h5;
  margin-top: 0;
  margin-bottom: 0;
}

.checkout-sidebar-basket-row,
.checkout-sidebar-basket-row hr {
  margin: 0 0 @padding-small-vertical 0;
  padding: 0 0 @padding-small-vertical 0;
}

.sidebar-subtotal {
  margin: 0;
  padding: 0;
}

.sidebar-subtotal .checkout-sidebar-basket-row:last-child {
  margin-bottom: 0;
}

.checkout-content .text-success {
  color: @brand-success;
  font-weight: bold;
}

.payment-title {}

.payment-desc {}

.payment-image {
  padding-top: 0.75em;
}
