/*!
 * Bootstrap v3.3.7 (http://getbootstrap.com)
 * Copyright 2011-2016 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */

//
// Load core variables and mixins
// --------------------------------------------------

@import "variables.less";
@import "mixins.less";


//
// Buttons
// --------------------------------------------------

// Common styles
.btn-default,
.btn-primary,
.btn-success,
.btn-info,
.btn-warning,
.btn-danger {
  text-shadow: 0 -1px 0 rgba(0,0,0,.2);
  @shadow: inset 0 1px 0 rgba(255,255,255,.15), 0 1px 1px rgba(0,0,0,.075);
  .box-shadow(@shadow);

  // Reset the shadow
  &:active,
  &.active {
    .box-shadow(inset 0 3px 5px rgba(0,0,0,.125));
  }

  &.disabled,
  &[disabled],
  fieldset[disabled] & {
    .box-shadow(none);
  }

  .badge {
    text-shadow: none;
  }
}

// Mixin for generating new styles
.btn-styles(@btn-color: #555) {
  .reset-filter(); // Disable gradients for IE9 because filter bleeds through rounded corners; see https://github.com/twbs/bootstrap/issues/10620
  background-repeat: repeat-x;
  border-color: darken(@btn-color, 14%);

  &:hover,
  &:focus  {
    background-color: darken(@btn-color, 12%);
    background-position: 0 -15px;
  }

  &:active,
  &.active {
    background-color: darken(@btn-color, 12%);
    border-color: darken(@btn-color, 14%);
  }

  &.disabled,
  &[disabled],
  fieldset[disabled] & {
    &,
    &:hover,
    &:focus,
    &.focus,
    &:active,
    &.active {
      background-color: darken(@btn-color, 12%);
      background-image: none;
    }
  }
}

// Common styles
.btn {
  // Remove the gradient for the pressed/active state
  &:active,
  &.active {
    background-image: none;
  }
}

// Apply the mixin to the buttons
.btn-default { .btn-styles(@btn-default-bg); border-color: @btn-default-border; }
.btn-primary { .btn-styles(@btn-primary-bg); }
.btn-success { .btn-styles(@btn-success-bg); }
.btn-info    { .btn-styles(@btn-info-bg); }
.btn-warning { .btn-styles(@btn-warning-bg); }
.btn-danger  { .btn-styles(@btn-danger-bg); }


//
// Images
// --------------------------------------------------

.thumbnail,
.img-thumbnail {
  .box-shadow(0 1px 2px rgba(0,0,0,.075));
}


//
// Dropdowns
// --------------------------------------------------

.dropdown-menu > li > a:hover,
.dropdown-menu > li > a:focus {
  background-color: darken(@dropdown-link-hover-bg, 5%);
}
.dropdown-menu > .active > a,
.dropdown-menu > .active > a:hover,
.dropdown-menu > .active > a:focus {
  background-color: darken(@dropdown-link-active-bg, 5%);
}


//
// Navbar
// --------------------------------------------------

// Default navbar
.navbar-default {
  .reset-filter(); // Remove gradient in IE<10 to fix bug where dropdowns don't get triggered
  border-radius: @navbar-border-radius;
  @shadow: inset 0 1px 0 rgba(255,255,255,.15), 0 1px 5px rgba(0,0,0,.075);
  .box-shadow(none);

  .navbar-nav > .open > a,
  .navbar-nav > .active > a {
    .box-shadow(none);
  }
}
.navbar-brand,
.navbar-nav > li > a {
  text-shadow: 0 1px 0 rgba(255,255,255,.25);
}

// Inverted navbar
.navbar-inverse {
  .reset-filter(); // Remove gradient in IE<10 to fix bug where dropdowns don't get triggered; see https://github.com/twbs/bootstrap/issues/10257
  border-radius: @navbar-border-radius;
  .navbar-nav > .open > a,
  .navbar-nav > .active > a {
    .box-shadow(none);
  }

  .navbar-brand,
  .navbar-nav > li > a {
    text-shadow: 0 -1px 0 rgba(0,0,0,.25);
  }
}

.navbar-sunrise-groups {
	margin-bottom: @line-height-computed;
}

// Undo rounded corners in static and fixed navbars
.navbar-static-top,
.navbar-fixed-top,
.navbar-fixed-bottom {
  border-radius: 0;
}


// Navbar Het Wijnhuis Custom Styles (Added by Marco)
.navbar-wijnhuis {
	font-family: @font-family-serif;
	font-size: @font-size-large;
	font-weight: bold;
}

.navbar-wijnhuis .badge {
	font-family: @font-family-sans-serif;
}

.navbar-brand-logo {
	height: @line-height-computed + (@navbar-padding-vertical * 2);
}

.navbar-brand-logo-lg {
	width: floor(((@screen-sm / 1.62) - (@padding-base-vertical * 2)));
	padding-top: @padding-base-vertical;
	padding-right: @padding-base-horizontal;
	padding-bottom: @navbar-padding-vertical;
}

.navbar-channel-logo {
	width: floor(((@screen-md / 2.5) - (@padding-base-vertical * 2)));
	padding-top: @padding-base-vertical;
	padding-right: @padding-base-horizontal;
	padding-bottom: @navbar-padding-vertical;
}


@media (max-width: @screen-sm-max) {
	.navbar-brand-logo-lg {
	width: floor(((@screen-sm / 2) - (@padding-base-vertical * 2)));
	}
}

// Het Wijnhuis service links

.wijnhuis-service-links {
	font-size: floor(((@font-size-large * 1.15)));
}

.wijnhuis-service-links .link-txt {
	font-family: @font-family-serif;
	font-size: floor(((@font-size-large * 1.25)));
	font-weight: bold;
}

// Het Wijnhuis navbar links
.navbar-wijnhuis .navbar-wijnhuis-link-md {
	font-size: @font-size-large;
	margin-top: (ceil(((@font-size-large * 1.15))) - @font-size-large + 1);
}

.navbar-wijnhuis .navbar-wijnhuis-link-lg {
	font-size: floor(((@font-size-large * 1.15)));
	margin-bottom: floor(((@navbar-padding-vertical / 2)));
}

// Kwestie van smaak search button
.navbar-sunrise-groups .kwestie-van-smaak-search-btn > a {
	font-family: @font-family-serif;
	font-weight: bold;
	background-color: @brand-primary;
	margin-bottom: floor(((@navbar-padding-vertical / 2)));
	margin-right: @navbar-padding-horizontal;
	.button-size(@padding-small-vertical; @padding-small-horizontal; @font-size-large; @line-height-base; @border-radius-large);
	padding-top: floor(((@navbar-padding-vertical / 2)));
}

.navbar-sunrise-groups .kwestie-van-smaak-search-btn > a:hover {
	background-color: lighten(@brand-primary, 5%) !important;
}

.btn-link.search-keyword {
	border-style: none;
	background-color: transparent;
}

//
// HW Theme 2.1 Topbar / Midbar / Bottombar
// --------------------------------------------------

// Topbar
.hw-topbar {
	background-color: @gray-lighter;
}

@media (min-width: @screen-tablet) {
	.hw-topbar .hw-topbar-first {
		padding-left: 0;
	}
}

.hw-topbar *, .hw-bottombar * {
	font-size: @font-size-small;
}

.hw-topbar .xs-logo-wrapper {
	margin: 0;
	padding: 0;
}

.hw-topbar .xs-logo {
	width: 34px;
}

.hw-topbar-item {
	padding: 9px 1em 8px 1em;
	display: inline-block;
}

.hw-topbar-item.channel-name {
	font-weight: bold;
	color: @brand-primary;
}

.hw-topbar .login-link .not-logged-in {
	color: @brand-primary;
}

.hw-topbar .logged-in > a,
.hw-topbar .logged-in > a > span {
	font-weight: bold;
	color: @brand-primary;
}

.hw-topbar .logged-in .user-name {}

.hw-topbar .logged-in .user-links-menu {}

.hw-topbar .logged-in .user-links-menu .user-link {}

.logged-in .user-links-menu a.user-link.log-out-link {
  font-weight: bold;
}



// Midbar
.hw-midbar {
	border-top: 1px solid @brand-info;
}

.btn.search-keyword {
	padding: 0 0 0 0.4em;
	font-size: @font-size-small;
}

.hw-midbar-left {
	padding-right: 0;
}

.hw-midbar-right {
	@media (min-width: @screen-tablet) {
		padding: 0;
	}
}

.hw-midbar .form-group {
	@media (max-width: @screen-lg-desktop) {
		margin-bottom: 4px;
	}
}

.hw-midbar .nav-pills li > a { // Promo pills dropdowns
	@media (max-width: @screen-lg-desktop) {
		font-size: @font-size-small;
		padding: 6px;
		margin-bottom: 4px;
	}
}

.hw-bottombar .navbar-nav > li > a { // Countries dropdowns list
	@media (min-width: @screen-lg-desktop) { // Large desktop size
		padding-left:  14px;
		padding-right: 14px;
	}
	@media (max-width: @screen-lg-desktop) { // Desktop size
		font-size: @font-size-small;
		padding: 4px;
		margin-right: 4px;
	}
	@media (min-width: @screen-tablet) and (max-width: @screen-desktop) { // Tablet size
		font-size: ceil((@font-size-small * 0.75));
		padding: 2px;
	}
}

.thumbnails-container {
	width: 40em;
	@media (max-width: @screen-desktop) {
		width: @screen-phone;
	}
}

// Bottombar
.navbar-default.hw-bottombar {
	background-color: @gray;
}
.navbar-default.hw-bottombar .navbar-nav > li > a {
	color: @gray-light;
}



//
//
// XS SMALL SCREENS (hw-bars rules)

@media (max-width: @screen-xs) {
	.hw-topbar {
		.navbar-fixed-top;
		height: 34px;
	}

	.hw-midbar {
		.navbar-fixed-top;
		top: 34px;
		z-index: 999;
	}

	.hw-bottombar {
		margin-top: 80px;
	}

	.hw-topbar > .container {
		padding-left: 0;
	}

	#channelName {
		max-width: 205px;
	}

	#channelName .hw-topbar-item.channel-name {
		max-height: 22px;
		overflow: hidden;
	}

	.hw-topbar .link-txt {
		display: none;
	}

	.nav > li > a {
		padding-left: 10px;
		padding-right: 10px;
	}
}




// HW navbar variant

@variant-bg-blue: #000e72;        // nice deep blue (#000e72)
@variant-bg-red:  #b12704;        // nice bright red (#b12704)
@variant-bg-mid: @gray;           // extra bg for collapsable elements (@gray)
@variant-fg-mid: @brand-primary;  // links and branding (@brand-primary)
@variant-fg: @gray-lighter;       // use white for readability
@variant-fg-contrast: #fff;       // nice warm yellow (#ffe300)

.hw-navbar-variant.brand-primary {
	background-color: @brand-primary !important;
}

.hw-navbar-variant.red {
	background-color: @variant-bg-red !important;
}

.hw-navbar-variant.blue {
	background-color: @variant-bg-blue !important;
}

.hw-navbar-variant {
	color: @variant-fg;

	.hw-topbar-item.channel-name,
	.navbar-nav > li > a,
	.login-link .not-logged-in {
		font-weight: bold;
		text-shadow: none;
	}

	.hw-topbar-item.channel-name,
	.not-logged-in,
	.logged-in,
	.logged-in > a:link > span {
		color: @variant-fg-contrast;
	}
	.logged-in.open > a > span {
		color: @variant-fg-mid;
	}

	.navbar-nav > li > a {
		color: @variant-fg;
	}
	.navbar-nav > li > a:hover > .login-link,
	.navbar-nav > li > a:hover > .not-logged-in,
	.navbar-nav > li > a:hover,
	.navbar-nav > li > a:focus {
		color: @variant-fg-contrast;
		text-shadow: none;
	}
	.navbar-nav > li > a {
		// color: @variant-fg !important; // default dropdown links color
	}
	.open > a,
	.open > a:hover,
	.open > a:focus {
		color: @variant-fg-mid !important;
	}
	.open > a > ul li a {
		background-color: @variant-bg-mid !important;
	}
}




//
// Carousel
// --------------------------------------------------
.carousel-inner p.small {
	font-size: floor(((@font-size-small * 0.85)));
	line-height: @font-size-small;
}

// Prevent images in Carousel with multiple images per slide to collapse in small screens
.carousel-multi img {
	max-width: 100%;
}

//
// Basket
// --------------------------------------------------


// Bundle of materials (BOM)
.bom-content-header {
	color: @brand-primary;
	font-weight: bold;
	margin-top: @padding-base-vertical;
  }

.bom-content-list {

}

.bom-content-list-item {
	font-weight: bold;
	margin-top: @padding-base-vertical;
}

.bom-component-content {
	min-height: 280px;      // Brute force patch to have components display nicely
}


//
// User Area
// --------------------------------------------------

// Savings (Besparing)
.user-header {
	padding-bottom: @navbar-padding-vertical;
	color: @brand-info;
	font-weight: bold;
}
.user-header-label {
	font-size: @font-size-h4;
}

//
// Alerts
// --------------------------------------------------

// Common styles
.alert {
  text-shadow: 0 1px 0 rgba(255,255,255,.2);
  @shadow: inset 0 1px 0 rgba(255,255,255,.25), 0 1px 2px rgba(0,0,0,.05);
  .box-shadow(@shadow);
}

// Mixin for generating new styles
.alert-styles(@color) {
  border-color: darken(@color, 15%);
}

// Apply the mixin to the alerts
.alert-success    { .alert-styles(@alert-success-bg); }
.alert-info       { .alert-styles(@alert-info-bg); }
.alert-warning    { .alert-styles(@alert-warning-bg); }
.alert-danger     { .alert-styles(@alert-danger-bg); }


//
// Progress bars
// --------------------------------------------------

// Give the progress background some depth
.progress {
  #gradient > .vertical(@start-color: darken(@progress-bg, 4%); @end-color: @progress-bg)
}

// Mixin for generating new styles
.progress-bar-styles(@color) {
  #gradient > .vertical(@start-color: @color; @end-color: darken(@color, 10%));
}

// Apply the mixin to the progress bars
.progress-bar            { .progress-bar-styles(@progress-bar-bg); }
.progress-bar-success    { .progress-bar-styles(@progress-bar-success-bg); }
.progress-bar-info       { .progress-bar-styles(@progress-bar-info-bg); }
.progress-bar-warning    { .progress-bar-styles(@progress-bar-warning-bg); }
.progress-bar-danger     { .progress-bar-styles(@progress-bar-danger-bg); }

// Reset the striped class because our mixins don't do multiple gradients and
// the above custom styles override the new `.progress-bar-striped` in v3.2.0.
.progress-bar-striped {
  #gradient > .striped();
}


//
// List groups
// --------------------------------------------------

.list-group {
  border-radius: @border-radius-base;
  .box-shadow(0 1px 2px rgba(0,0,0,.075));
}
.list-group-item.active,
.list-group-item.active:hover,
.list-group-item.active:focus {
  text-shadow: 0 -1px 0 darken(@list-group-active-bg, 10%);
  #gradient > .vertical(@start-color: @list-group-active-bg; @end-color: darken(@list-group-active-bg, 7.5%));
  border-color: darken(@list-group-active-border, 7.5%);

  .badge {
    text-shadow: none;
  }
}


//
// Panels
// --------------------------------------------------

// Common styles
.panel {
  .box-shadow(0 1px 2px rgba(0,0,0,.05));
}

// Mixin for generating new styles
.panel-heading-styles(@color) {
}

// Apply the mixin to the panel headings only
.panel-default > .panel-heading   { .panel-heading-styles(@panel-default-heading-bg); }
.panel-primary > .panel-heading   { .panel-heading-styles(@panel-primary-heading-bg); }
.panel-success > .panel-heading   { .panel-heading-styles(@panel-success-heading-bg); }
.panel-info > .panel-heading      { .panel-heading-styles(@panel-info-heading-bg); }
.panel-warning > .panel-heading   { .panel-heading-styles(@panel-warning-heading-bg); }
.panel-danger > .panel-heading    { .panel-heading-styles(@panel-danger-heading-bg); }


//
// Wells
// --------------------------------------------------

.well {
  #gradient > .vertical(@start-color: darken(@well-bg, 5%); @end-color: @well-bg);
  border-color: darken(@well-bg, 10%);
  @shadow: inset 0 1px 3px rgba(0,0,0,.05), 0 1px 0 rgba(255,255,255,.1);
  .box-shadow(@shadow);
}


// Footer
#footer {
	border-top: 1px solid @gray-light;
	background-color: transparent;
	font-size: ceil((@font-size-base * 0.9));
	padding-top: (@font-size-base * 3);
	padding-bottom: (@font-size-base * 2);
	margin-top: (@font-size-base * 3);
}

#footer .row {
	margin-bottom: @font-size-base;
}



//
// START: 2018 responsive header
// --------------------------------------------------

@pre-header-height:             33px;
@pre-header-color:              @gray-lighter;
@pre-header-bg:                 @gray;
@pre-header-hover-color:        #fff;

.navbar .full-dropdown-menu a,
.navbar .dropdown-menu > li > a {
  color: #fff;
}

.navbar-inverse .full-dropdown-menu a {
  font-weight: bold;
}

.full-dropdown-menu li > a {
  margin-left: -0.6em;
  padding: 0.2em 0.6em;
  display: block;
}

.navbar-inverse .full-dropdown-menu,
.navbar-inverse .dropdown-menu {
  background-color: @gray-darker;
  color: @gray-light;
}

.navbar-inverse .full-dropdown-menu li > a:active,
.navbar-inverse .full-dropdown-menu li > a:focus,
.navbar-inverse .full-dropdown-menu li > a:hover {
  background-color: @brand-primary;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}

.navbar-inverse .dropdown-menu > li > a:active,
.navbar-inverse .dropdown-menu > li > a:focus,
.navbar-inverse .dropdown-menu > li > a:hover {
  background-color: @brand-primary;
}

.nav > li.dropdown.open a.dropdown-toggle {
  background-color: @brand-primary; // use same background color as :hover to show which menu user clicked
}

.navbar-inverse .full-dropdown-menu h1,
.navbar-inverse .full-dropdown-menu h2,
.navbar-inverse .full-dropdown-menu h3 {
  color: lighten(@gray, 15%);
  text-transform: uppercase;
  margin-bottom: 2px;
}

.navbar-inverse .full-dropdown-menu .divider,
.navbar-inverse .full-dropdown-menu hr {
  background-color: lighten(@gray, 15%);
  border-color: lighten(@gray, 15%);
}

.navbar-inverse .navbar-toggle {
  border-color: @gray-light;
  color: @gray-light;
}

button.navbar-toggle {
  margin-bottom: 0;
}

.navbar-inverse .navbar-nav .dropdown > a {
  font-weight: bold;
}

.navbar-inverse a:hover .badge {
  background-color: @gray-darker;
}

#topbar-search .input-group-addon,
input#keyword {
  background-color: #eee;
  border-color: @brand-primary;
}

input#keyword::placeholder {
  color: darken(@gray-light, 15%);
}

a#topbar-search-submit,
input#keyword {
  color: @gray;
}

#topbar-search .input-group-addon {
  background-color: @brand-primary;
}

.top-nav-logo {
  height: @navbar-height;
}

// Specific rules for screens bigger than mobile
@media (min-width: @screen-xs-max) {
  #pre-header {
    background-color: @pre-header-bg;
    padding-top: 6px;
    padding-bottom: 6px;
    height: @pre-header-height;
    font-weight: 300;
  }

  #pre-header .pre-header-item {
    color: @pre-header-color;
    margin-right: 3em;
  }

  #pre-header a.pre-header-item:hover {
    color: @pre-header-hover-color;
  }

  #header.navbar-fixed-top {
    top: @pre-header-height;
  }

  #page-content-start {
    height: (@pre-header-height + @navbar-height); // header height + pre header height
  }

  #topbar-search {
    margin-right: 1em;
  }

  /* dropdown on hover */
  #header-topnav-collapse li.toggle-on-hover:hover > ul.dropdown-menu {
    display: block;
  }

  /*full width dropdown content*/
  .nav > li.full-dropdown-menu-toggle {
    position: initial;
  }

  ul.full-dropdown-menu {
    width: 100%;
    border: none;
  }
}

// Specific rules for screens between mobile and desktop
@media (min-width: @screen-xs-min) and (max-width: @screen-sm-max) {
  .logged-in .user-name .link-txt {
    display: none;
  }

  .navbar-collapse.collapse.in .logged-in .user-name .link-txt {
    display: inline;
  }

  .navbar-fixed-top .navbar-collapse,
  .navbar-fixed-bottom .navbar-collapse {
    max-height: 280px;
  }

  #header-topnav-collapse .channel-name-mobile {
    font-size: @font-size-base;
    font-family: @font-family-sans-serif;
    color: darken(@gray-light, 25%);
    margin: 0;
    padding: 0;
    display: block;
  }

  #pre-header .pre-header-item {
    margin-right: 1.5em;
  }

  .container {
    width: auto; // automatically adjust .container in landscape mode
  }
}

// Specific rules for mobile
@media (max-width: @screen-sm-min) {
  #page-content-start {
    height: @navbar-height;
  }

  #header > .container > .navbar-header {
    margin-left: -15px;
  }

  #header-topnav-collapse > ul.nav {
    margin-top: 0;
  }

  #topbar-search {
    margin-top: 0;
    margin-bottom: 0;
  }

  #page-content-start {
    height: @navbar-height;
  }
}

// --------------------------------------------------
// END: 2018 responsive header
//
